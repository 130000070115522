import { useEffect, useState } from "react";
import { registerLog } from "libs/analytics";
import MainLayout from "views/layout/MainLayout";
import { Container  } from "./styled";
import SliderCarousel from "components/SliderCarousel";
import { OPTIONS_FORM_REAL_STATE } from "./constants";
import ServicesForm from "components/Forms/services-form";
import { Extras} from "../../components/Extras/extras";
import { useGetFirestoreCollection } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";
// import BannerFooterMobile from "components/banner-footer-mobile/banner-footer-mobile";
// import { BREAKPOINTS } from "constants/index";
import {Properties} from 'modules/services/components/Properties/properties'
import { useTranslation } from "react-i18next";


const RealState = () => {
  const { collectionData: banners, fetchCollection: fetchBanners } = useGetFirestoreCollection();
  const { collectionData: technicalServiceData, fetchCollection: fetchTechnicalServiceData } = useGetFirestoreCollection();
  const { collectionData: properties, fetchCollection: fetchProperties } = useGetFirestoreCollection();
  const { t } = useTranslation();
  
  const [bannerImages, setBannerImages] = useState<string[]>([]);

  useEffect(() => {
    fetchBanners({
      path: "PANAMA/Services/Categories/RealState/Banners",
    });

    fetchTechnicalServiceData({
      path: "PANAMA/Services/Categories/TechnicalServices/Posts",
    });

    fetchProperties({ 
      path: "PANAMA/Services/Categories/RealState/Posts",
      filters: [
        {
          field: "active",
          operator: "==",
          value: true,
        },
      ],
    });

    registerLog("Services main real state", {
      page_title: "Service main real state",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (banners && banners.length > 0) {
      const listBanners: string[] = [];

      banners.forEach((doc: any) => {
        if (window.innerWidth < 768) {
          listBanners.push(doc.imagesMobile.es);
        } else {
          listBanners.push(doc.imagesDesktop.es);
        }
      });
      setBannerImages(listBanners);
    }
  }, [banners]);

  return (
    <MainLayout type="services"> 
      <Container>
        <SliderCarousel images={ bannerImages } />
        <ServicesForm
          category="RealState"
          titleFormDescription={t('do_you_have_a_real_estate_need')}
          descriptionForm={t('at_whatdoyouneed7_we_understand_that_each_person_has_unique_needs_in_the_real_estate_field')}
          options={ OPTIONS_FORM_REAL_STATE } />
        <Properties properties={ properties || []} />
        <Extras data={ technicalServiceData } hasRedirectToDetail hasLink />
        {/* {window.innerWidth < BREAKPOINTS.lg && (
          <BannerFooterMobile
            secondaryText="Promociona tus propiedades con nosotros y aumenta tu visibilidad con nuestra plataforma!"
            text="¿Alquilas o vendes alguna propiedad?"
          />
        )} */}
      </Container>
    </MainLayout>
  );
};

export default RealState;
