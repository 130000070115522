import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Container, ContentForm, InputWrap, InputsContainer, SectionTitleWithBackground, Grid } from "../styled";
import ImagePickerV2 from "ui/ImagePickerV2"
import Select from "ui/Select";
import CardOperationType from "components/Classifieds/card-operation-type/card-operation-type";
import { Formik, Form, Field } from 'formik';
import Input from 'ui/TextInput';
import { P, H2 } from "ui/Text";
import GoogleMapInput from "ui/GoogleMapInput";
import { TextError } from "ui/Text";
import TextArea from "ui/TextArea";
import { Button } from "ui/Buttons";
import { useNotification } from "libs/context/AlertContext";
import YesOrNot from "components/YesOrNot";
import { classifiedRealStateFormSchema, OPTIONS_TYPE_PROPERTY, OPTIONS_TYPE_SELLER } from '../main'
import { IRealStateClassified } from "types";
import { MAX_LENGTH_DESCRIPTION, MAX_LENGTH_TITLE, MAX_IMAGES_12 } from "constants/constants";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { onlyNumbers } from "utils/common";
// Icons
import { EnumClassifiedPropertyIconUrls } from 'constants/classifieds';
import { getPropertyValues } from "utils/classifieds/classifieds";
interface Props {
    category?: string;
    categoryType?: string;
    userUid: string;
    onSubmit?: (values: IRealStateClassified) => void;
    createClassifiedSuccess: boolean;
    language?: string;
    initialValues?: any;
}

const NewClassifiedForm: FC<Props> = (props: Props) => {
    const { category, onSubmit, userUid, createClassifiedSuccess, categoryType, initialValues } = props;
    const formRef: any = useRef(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showError } = useNotification();

    const [, setFormErrors] = useState<any>({});

    const initialPosition = Object.keys.length > 0 ? initialValues?.location?.value?.coordinates : { lat: 8.98251176638921, lng: -79.528036556417 };
    console.log('Estos son los valores iniciales', initialValues);
    

    useEffect(() => {
        if (createClassifiedSuccess && formRef.current) {
            formRef.current.resetForm();
            setTimeout(() => {
                navigate('/classifieds');
            }, 2000);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createClassifiedSuccess]);

    const buttonText = initialValues ? t('edit_classified') : t('create_classified');

    return (
        <Container>
            <H2 style={{ color: '#000' }}>{category || ''}</H2>
            <Formik
                innerRef={formRef}
                initialValues={{
                    title: initialValues?.title || '',
                    description: initialValues?.description || '',
                    category: initialValues?.category || category,
                    categoryType: categoryType || '',
                    typeOfOperation: initialValues?.typeOfOperation || '',
                    typeOfProperty: initialValues?.typeOfProperty || '',
                    squareMeters: getPropertyValues(initialValues?.properties, 'Square meters') || '',
                    includeSocialArea: getPropertyValues(initialValues?.properties, 'Social Area') || false,
                    includeGym: getPropertyValues(initialValues?.properties, 'Gym') || false,
                    isFurnished: getPropertyValues(initialValues?.properties, 'Furnished') || false,
                    numberOfRooms: getPropertyValues(initialValues?.properties, 'Rooms') || '',
                    numberOfBathrooms: getPropertyValues(initialValues?.properties, 'Bathrooms') || '',
                    numberOfParking: getPropertyValues(initialValues?.properties, 'Parkings') || '',
                    levelFurnished: initialValues?.levelFurnished || '',
                    location: initialValues?.location || {},
                    price: initialValues?.price || '',
                    sellerType: initialValues?.sellerType || '',
                    images: initialValues?.images || [],
                    userId: userUid,
                }}
                validationSchema={classifiedRealStateFormSchema}
                onSubmit={(values: IRealStateClassified) => {
                    onSubmit && onSubmit(values);
                }}
            >
                {({ errors, touched, values, setFieldValue, handleSubmit, handleChange }) => (
                    <ContentForm>
                        <Form>
                            <div>
                                <ImagePickerV2
                                    maxImages={MAX_IMAGES_12}
                                    listImages={values?.images || [] as any}
                                    onChange={(files) => {
                                        setFieldValue('images', files);
                                    }}
                                />
                            </div>
                            <InputsContainer>
                                <SectionTitleWithBackground>{t('general_information')}</SectionTitleWithBackground>
                                <InputWrap>
                                    <Field as={Input} label={t('classified_title')} name="title" maxLength={MAX_LENGTH_TITLE} />
                                    <TextError>{touched.title && errors.title && errors.title}</TextError>
                                </InputWrap>
                                <InputWrap>
                                    <Field as={TextArea} label={t('description')} name="description" maxLength={MAX_LENGTH_DESCRIPTION} />
                                    <TextError>{touched.description && errors.description && errors.description}</TextError>
                                </InputWrap>
                                <SectionTitleWithBackground>{t('property_information')}</SectionTitleWithBackground>
                                <InputWrap>
                                    <Field
                                        as={Select}
                                        label={t('property_type')}
                                        value={values.typeOfProperty}
                                        options={OPTIONS_TYPE_PROPERTY}
                                        onChange={(e) => { setFieldValue('typeOfProperty', e); }}
                                        hideNeverChoice
                                    />
                                    <TextError>{touched.typeOfProperty && errors.typeOfProperty && errors.typeOfProperty}</TextError>
                                </InputWrap>
                                <Grid>
                                    <InputWrap>
                                        <Field as={Input} value={values.numberOfRooms} onChange={(e) => { handleChange('numberOfRooms')(onlyNumbers(e.target.value)); }} label='Habitaciones' maxLength={2} RightIcon={EnumClassifiedPropertyIconUrls.Rooms} />
                                        <TextError>{touched.numberOfRooms && errors.numberOfRooms && errors.numberOfRooms}</TextError>
                                    </InputWrap>
                                    <InputWrap>
                                        <Field as={Input} label='Baños' maxLength={2} RightIcon={EnumClassifiedPropertyIconUrls.Bathrooms} value={values.numberOfBathrooms} onChange={(e) => { handleChange('numberOfBathrooms')(onlyNumbers(e.target.value)); }} />
                                        <TextError>{touched.numberOfBathrooms && errors.numberOfBathrooms && errors.numberOfBathrooms}</TextError>
                                    </InputWrap>
                                    <InputWrap>
                                        <Field as={Input} label='Estacionamientos' maxLength={2} RightIcon={EnumClassifiedPropertyIconUrls.Parkings} value={values.numberOfParking} onChange={(e) => { handleChange('numberOfParking')(onlyNumbers(e.target.value)); }} />
                                        <TextError>{touched.numberOfParking && errors.numberOfParking && errors.numberOfParking}</TextError>
                                    </InputWrap>
                                    <InputWrap>
                                        <Field as={Input} label='Metros cuadrados' maxLength={4} RightIcon={EnumClassifiedPropertyIconUrls.SquareMeters} value={values.squareMeters} onChange={(e) => { handleChange('squareMeters')(onlyNumbers(e.target.value)); }} />
                                        <TextError>{touched.squareMeters && errors.squareMeters && errors.squareMeters}</TextError>
                                    </InputWrap>
                                </Grid>
                                <Grid columns={window.innerWidth < 768 ? 2 : 3}>
                                    <InputWrap>
                                        <YesOrNot
                                            label={t('include_social_area')}
                                            isYes={values.includeSocialArea}
                                            onChange={(e) => { setFieldValue('includeSocialArea', e); }}
                                        />
                                        <TextError>{touched.includeSocialArea && errors.includeSocialArea && errors.includeSocialArea}</TextError>
                                    </InputWrap>
                                    <InputWrap>
                                        <YesOrNot
                                            label={t('include_gym')}
                                            isYes={values.includeGym}
                                            onChange={(e) => { setFieldValue('includeGym', e); }}
                                        />
                                        <TextError>{touched.includeGym && errors.includeGym && errors.includeGym}</TextError>
                                    </InputWrap>
                                    <InputWrap>
                                        <YesOrNot
                                            label={t('is_furnished')}
                                            isYes={values.isFurnished}
                                            onChange={(e) => { setFieldValue('isFurnished', e); }}
                                        />
                                        <TextError>{touched.isFurnished && errors.isFurnished && errors.isFurnished}</TextError>
                                    </InputWrap>
                                </Grid>

                                <InputWrap>
                                    <Field
                                        as={CardOperationType}
                                        label={t('operation_type')}
                                        value={values.typeOfOperation ? [values.typeOfOperation].filter((item) => item !== '') : []}
                                        onChange={(e) => { setFieldValue('typeOfOperation', values.typeOfOperation ? e[1] : e[0]); }}
                                        disabled={false}
                                        errorMessage={touched.typeOfOperation && errors.typeOfOperation && <div>{errors.typeOfOperation}</div>}
                                    />
                                    <TextError>{touched.typeOfOperation && errors.typeOfOperation && <div>{errors.typeOfOperation}</div>}</TextError>
                                </InputWrap>
                                <SectionTitleWithBackground>{t('additional_information')}</SectionTitleWithBackground>
                                <InputWrap>
                                    <P>{t('location')}</P>
                                    <GoogleMapInput
                                        initialPosition={initialPosition}
                                        onChange={(e) => {
                                            setFieldValue('location', e);
                                        }}
                                        fullLocation={values?.location as any}
                                    />
                                    <TextError>{touched.location && errors.location && <div>{errors.location}</div>}</TextError>
                                </InputWrap>
                                <InputWrap>
                                    <Field
                                        as={Select}
                                        label={t('seller_type')}
                                        value={values.sellerType}
                                        options={OPTIONS_TYPE_SELLER}
                                        onChange={(e) => { setFieldValue('sellerType', e); }}
                                        hideNeverChoice
                                    />
                                    <TextError> {touched.sellerType && errors.sellerType && <div>{errors.sellerType}</div>}</TextError>
                                </InputWrap>
                                <SectionTitleWithBackground>{t('price')}</SectionTitleWithBackground>
                                <InputWrap>
                                    <Field as={Input} label={t('price')} maxLength={12} value={values.price} onChange={(e) => { handleChange('price')(onlyNumbers(e.target.value)); }} />
                                    <TextError>{touched.price && errors.price && <div>{errors.price}</div>}</TextError>
                                </InputWrap>
                            </InputsContainer>
                            <InputsContainer style={{ marginTop: '2rem' }}>
                                <Button
                                    type="submit"
                                    onClick={() => handleSubmit()}
                                    disabled={Object.keys(errors).length > 0}
                                    onDisabledClick={() => {
                                        showError(t(`${t('please_complete_all_required_fields')
                                            }: ${Object.values(errors).map((error: any) => {
                                                return error + ', '
                                            })}
                                            `))
                                        setFormErrors(errors)
                                    }

                                    }
                                    IconRight={<NewspaperIcon />}
                                >
                                    {buttonText}
                                </Button>
                            </InputsContainer>
                        </Form>
                    </ContentForm>
                )}
            </Formik>
        </Container>
    );
}

export default NewClassifiedForm;
