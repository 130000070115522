import { useEffect, useState } from "react";
import { registerLog } from "libs/analytics";

import { Container } from "./styled";
import MainLayout from "views/layout/MainLayout";
import SliderCarousel from "components/SliderCarousel";
import ServicesForm from "components/Forms/services-form";
import ContentMarketing from "modules/services/components/content-marketing";
import ExtrasServicesContainer from "components/extras-services-container";
import { MarketingTypeCard, OPTIONS_FORM_MARKETING } from "./constants";
import { useGetFirestoreCollection } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";
import BannerOnlyText from "components/banner-only-text/banner-only-text";
import BannerFooterMobile from "components/banner-footer-mobile/banner-footer-mobile";
import { BREAKPOINTS } from "constants/index";
import { useTranslation } from "react-i18next";


const Marketing = () => {
  const { collectionData: banners, fetchCollection: fetchBanners } = useGetFirestoreCollection();
  const { collectionData: marketingData, fetchCollection: fetchMarketingData } = useGetFirestoreCollection();
  const [bannerImages, setBannerImages] = useState<string[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    fetchMarketingData({
      path: "PANAMA/Services/Categories/Marketing/Posts",
    });

    fetchBanners({
      path: "PANAMA/Services/Categories/Marketing/MainBanners",
    });

    registerLog("Services screen", {
      page_title: "Services",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, [fetchBanners, fetchMarketingData]);


  useEffect(() => {
    if (banners && banners.length > 0) {
      const listBanners: string[] = [];

      banners.forEach((doc: any) => {
        if (window.innerWidth < 768) {
          listBanners.push(doc.imagesMobile.es);
        } else {
          listBanners.push(doc.imagesDesktop.es);
        }
      });
      setBannerImages(listBanners);
    }
  }, [banners]);

  const processArray = (arr) => {
    const arrLength = arr?.length;
    const thirdLength = Math.floor(arrLength / 3);

    const firstPart = arr.slice(0, thirdLength);
    const secondPart = arr.slice(thirdLength, 2 * thirdLength);
    const thirdPart = arr.slice(2 * thirdLength);

    return [firstPart, secondPart, thirdPart];
  };

  const marketingCards = marketingData?.filter((item) => item.type === MarketingTypeCard.general);
  const marketingPlans = marketingData?.filter((item) => item.type === MarketingTypeCard.plans);
  console.log('marketingPlans', marketingPlans);
  const marketingExtraServices = marketingData?.filter((item) => item.type === MarketingTypeCard.extras);

  const listMarketingData = [...(marketingCards || [])];

  const [firstMarketingList, secondMarketingList, thirdMarketingList] = processArray(listMarketingData);

  thirdMarketingList.reverse();

  return (
    <MainLayout type="services">
      <Container>
        <SliderCarousel images={bannerImages} />
        <ServicesForm
          category="Marketing"
          titleFormDescription={t('do_you_have_a_marketing_need')}
          descriptionForm={t('at_whatdoyounees7_we_recognize_that_each_client_has_unique_needs_in_the_world_of_marketing')}
          options={OPTIONS_FORM_MARKETING} />
        {window.innerWidth > BREAKPOINTS.lg && <BannerOnlyText />} 
        <ContentMarketing services={thirdMarketingList || []} />
        <ExtrasServicesContainer
          data={marketingPlans || []}
          title={t('our_plans_for_you')}
          backgroundColor="#E7EDF5"
          hasLink={false}
          hasRedirectToDetail={true}
        />
        <ContentMarketing services={secondMarketingList || []} />
        <ExtrasServicesContainer
          data={marketingExtraServices || []}
          title={t('web_development')}
          backgroundColor="#E7EDF5"
          hasLink={false}
          hasRedirectToDetail={true}
        />
        <ContentMarketing services={firstMarketingList || []} />
        {window.innerWidth < BREAKPOINTS.lg &&
          <BannerFooterMobile secondaryText={`${t('increase_your_sales_generate_traffic_and_grow_exponentially')}`} text={`${t('advertise_in_the_most_important_media')}`} />
        } 
      </Container>
    </MainLayout>
  );
};

export default Marketing;
