import { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import MainLayout from "views/layout/MainLayout";
import ModalLoader from "ui/ModalLoader";
import { useSelector, useDispatch } from "react-redux";
import { H2 } from "ui/Text";
import { Button, BackButton } from "ui/Buttons";
import useAddToCart from 'hooks/cart/useAddToCart';
import { Container, Grid, GridItem, ResumePriceContainer, Price } from './styled'
import SingleProductCheckout from "components/cards/single-product-checkout";
import { useGetFirestoreDoc } from "hooks/useGetDocumentFirestore/useGetDocumentFirestore";
import { totalCart, itemsCart, getCartSagasTrigger, updateCartItemSagasTrigger, cleanCart } from 'redux/cart';
import { setLastStep, setUidCheckout, getUidCheckout } from "redux/checkout";
import { capitalize } from "utils/common";
import { CurrencySymbol } from "constants/common";
import { PRODUCT_MOCKUP } from './main'
import useGenerateFirestoreId from "hooks/database/firestore/use-get-firestore-unique-id/use-get-firestore-unique-id";

const CheckoutSingleProduct = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const addToCart = useAddToCart();
    const { t } = useTranslation();
    const { fetchDoc, docData } = useGetFirestoreDoc();
    const { generateId, uid: uidOrder, loading: loadingGenerateId, error: errorGenerateId } = useGenerateFirestoreId();
    const urlParams = new URLSearchParams(location.search);
    const uid = urlParams.get('uid');
    const uidCheckout = useSelector(getUidCheckout);
    const total = useSelector(totalCart);
    const items = useSelector(itemsCart);
    const mainProductCategory = capitalize(urlParams.get('mainProductCategory') || '');
    const country = urlParams.get('country');


    const pathProduct = `${country}/Products/Categories/${mainProductCategory}/Posts/`;

    const [product, setProduct] = useState<any>();

    const handleUpdateQuantity = useCallback((idItem: string | number, quantity: number) => {
        console.log('nueva cantidad', quantity, 'id', idItem);
        dispatch(updateCartItemSagasTrigger.run({ id: idItem, count: quantity }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBack = () => {
        dispatch(cleanCart());
        dispatch(setLastStep(0));
        navigate(-1);
    }

    const getTotalItems = useCallback(() => {
        const totalItems = items.reduce((acc, item) => {
            return acc + item.count;
        }, 0)
        return totalItems;
    }, [items]);



    useEffect(() => {
        if (uid) {
            fetchDoc({
                path: pathProduct,
                uid: uid,
            });
        }

        if (!uidCheckout) {
            generateId({ path: 'Checkouts' });
        }
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [uid, pathProduct, uidCheckout]);

    useEffect(() => {
        if (errorGenerateId) {
            alert('Ha ocurrido un error al generar el id de la orden');
        }

        if (uidOrder) {
            dispatch(setUidCheckout(uidOrder));
        }
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [errorGenerateId, uidOrder]);

    useEffect(() => {
        if (docData) {
            setProduct({ ...docData, uid: docData.id });

            if (!items.length) {
                addToCart({
                    id: docData.id,
                    name: docData.name,
                    price: docData.price,
                    images: docData.images,
                } as any);
            }

            window.scrollTo(0, 0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docData]);

    useEffect(() => {
        // @ts-ignore
        dispatch(getCartSagasTrigger.run());
    }, [total, dispatch])


    return (
        <MainLayout>
            <Container>
                <BackButton onClick={handleBack} />
                <H2 style={{ margin: '1rem 0' }}>{t("checkout")}</H2>
                <Grid>
                    <GridItem>
                        <SingleProductCheckout product={product || PRODUCT_MOCKUP} quantity={getTotalItems()} onQuantityChange={(e) => {
                            handleUpdateQuantity(product.id, e);
                        }} />
                    </GridItem>
                    <GridItem alignItems='flex-end'>
                        <ResumePriceContainer>
                            <div>
                                <h2>{t('quantity')}</h2>
                                <p>{getTotalItems()}</p>
                            </div>
                            {
                                product?.price ? (
                                    <div>
                                        <h2>{t('subtotal')}</h2>
                                        <Price>{CurrencySymbol[product.currency]}{parseFloat(total)?.toFixed(2)}</Price>
                                    </div>
                                ) : null
                            }
                            <Link to={`/checkout?uid=${uid}&mainProductCategory=${mainProductCategory}&country=${country}&quantity=${getTotalItems()}`}>
                                <Button onClick={() => { }}>{t('buy')}</Button>
                            </Link>
                        </ResumePriceContainer>
                    </GridItem>
                </Grid>
            </Container>
            <ModalLoader isLoading={loadingGenerateId} />
        </MainLayout>
    );
}

export default CheckoutSingleProduct;