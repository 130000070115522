import MainLayout from "views/layout/MainLayout";
import { Container, SubContainer, ClassifiedResumeView, ChatContainer, ImageClassified, Row } from './styled';
import { BackButton } from "ui/Buttons";
import ModalLoader from "ui/ModalLoader";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { H5, P } from "ui/Text";
import ChatSessionClassifiedContainer from "views/containers/Classifieds/chat-session-classified-container/chat-session-classified-container";
import useInitClassifiedChat from "hooks/modules/classifieds/use-init-classified-chat/use-init-classified-chat";
import { useGetFirestoreDoc } from "hooks/useGetDocumentFirestore";
import useGetUserById from "hooks/user/use-get-user-by-id/use-get-user-by-id";
import { useEffect, useState } from "react";
import { formatDate } from 'utils/format-date';
import { firestoreFull as firestore } from "config/firebase"; // Asegúrate de que esta es la correcta configuración de Firestore
import { onSnapshot, doc } from 'firebase/firestore';
import { getCountry } from "redux/common";
import { userSelector } from "redux/user";
import PersonIcon from '@mui/icons-material/Person';
import PinDropIcon from '@mui/icons-material/PinDrop';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {THEME} from 'constants/theme/theme';

const ChatSessionClassified = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const CLASSIFIED_ID = location.state?.classifiedId;
  const CONSULTANT_ID = location.state?.consultantId;
  const OWNER_CLASSIFIED_ID = location.state?.ownerClassifiedId;
  const COUNTRY = useSelector(getCountry).toUpperCase();
  const user = useSelector(userSelector)?.userData;
  const currentUserID = user?.id;

  const { docData: classifiedData, fetchDoc: fetchClassified, loadingGetFirestoreDoc: loadingProduct } = useGetFirestoreDoc();
  const { loading, error, initClassifiedChat } = useInitClassifiedChat();
  const { getUserById: getOwnerUserData, userData: ownerUserData, error: userError } = useGetUserById();
  const { getUserById: getConsultantUserData, userData: consultantUserData, error: consultantError} = useGetUserById();

  const [listMessages, setListMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (CLASSIFIED_ID && CONSULTANT_ID && OWNER_CLASSIFIED_ID && COUNTRY) {
      initClassifiedChat({
        classifiedId: CLASSIFIED_ID,
        consultantId: CONSULTANT_ID,
        ownerClassifiedId: OWNER_CLASSIFIED_ID,
        country: COUNTRY,
      });
    }
  }, [CLASSIFIED_ID, CONSULTANT_ID, OWNER_CLASSIFIED_ID, COUNTRY, initClassifiedChat]);

  useEffect(() => {
    if (userError) {
      console.error('Error:', userError);
    }

    if (consultantError) {
      console.error('Error:', consultantError);
    }
  }, [userError, consultantError]);


  useEffect(() => {
    if (CLASSIFIED_ID) {
      fetchClassified({
        path: `${COUNTRY}/Classifieds/Posts`,
        uid: CLASSIFIED_ID,
      });
    }
  }, [CLASSIFIED_ID, fetchClassified, COUNTRY]);

  useEffect(() => {
    if (OWNER_CLASSIFIED_ID) {
      getOwnerUserData(OWNER_CLASSIFIED_ID);
    }

    if (CONSULTANT_ID) {
      getConsultantUserData(CONSULTANT_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OWNER_CLASSIFIED_ID, CONSULTANT_ID, currentUserID]);


  // Listener para el documento del chat en tiempo real
  useEffect(() => {
    setIsLoading(true);
    if (CLASSIFIED_ID && CONSULTANT_ID && OWNER_CLASSIFIED_ID) {
      setIsLoading(false);
      const chatDocId = `${CLASSIFIED_ID}_${CONSULTANT_ID}_${OWNER_CLASSIFIED_ID}`;
      const chatDocRef = doc(firestore, `ChatsClassifieds/${COUNTRY}/Chats/${chatDocId}`);

      // Listener para obtener el documento en tiempo real
      const unsubscribe = onSnapshot(chatDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const chatData = docSnapshot.data();
          console.log('Chat Data:', chatData); // Log para verificar los datos del documento
          setListMessages(chatData?.messages || []); // Actualizar los mensajes desde el documento
        } else {
          console.log('No such document!');
        }
      });

      // Limpiar el listener cuando se desmonte el componente
      return () => unsubscribe();
    }
  }, [CLASSIFIED_ID, CONSULTANT_ID, OWNER_CLASSIFIED_ID, COUNTRY, ]);

  useEffect(() => {
    if (error) {
      console.error('Error:', error);
    }
  }, [error]);

  if (loadingProduct || isLoading) {
    return <ModalLoader isLoading={true} />;
  }

  return (
    <MainLayout type="classifieds">
      <Container>
        <BackButton onClick={() => navigate(-1)} />
        <SubContainer>
          <ClassifiedResumeView>
            <H5>{classifiedData?.title}</H5>
            <ImageClassified src={classifiedData?.images[0]} alt={classifiedData?.title} />
            <Row>
              <PersonIcon style={{color: THEME.colors.classifieds}}/>
              <P fontWeight={700}>{ownerUserData?.displayName || ''}</P>
            </Row>
            <Row>
              <PinDropIcon style={{color: THEME.colors.classifieds}}/>
              <P fontSize='12px' >{classifiedData?.location?.label}</P>
            </Row>
            <Row>
              <CalendarMonthIcon style={{color: THEME.colors.classifieds}}/>
              <P fontWeight={600} fontSize='14px'>{formatDate(classifiedData?.createdAt)}</P>
            </Row>
          </ClassifiedResumeView>
          <ChatContainer>
            {loading ? (
              <P>Cargando el chat...</P>
            ) : (
              <ChatSessionClassifiedContainer  
                ownerClassifiedId={OWNER_CLASSIFIED_ID} 
                ownerClassifiedName={ownerUserData?.displayName || ''}
                classifiedId={CLASSIFIED_ID} 
                consultantId={CONSULTANT_ID} 
                consultantName={consultantUserData?.displayName || ''}
                country={COUNTRY}
                listMessages={listMessages} // Usamos los mensajes en tiempo real
              />
            )}
          </ChatContainer>
        </SubContainer>
      </Container>
    </MainLayout>
  );
};

export default ChatSessionClassified;
