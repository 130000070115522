import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useCallback } from "react";
import { Container, Row, DataCardContainer, ButtonContainer } from "./styled";
import Input from "ui/TextInput";
import { Button } from "ui/Buttons";
import Checkbox from "ui/Checkbox";
import CreditCardBrands from "assets/credit-card-logos.jpg";
import ModalLoader from "ui/ModalLoader/modal-loader";
import { useNotification } from "libs/context/AlertContext";
import authAndCapturePaguelofacil from "services/payments/paguelofacil/auth-capture-paguelofacil";
import {
  getCardType,
  imageCardType,
  // expirationDateSchema,
  AuthAndCapturePaguelofacil,
  CreditCardAuthCaptureProps,
  validationSchema,
  ResponsePaguelofacil,
} from "./main";

const CreditCardAuthCapture = (props: CreditCardAuthCaptureProps) => {
  const { showSuccess, showError } = useNotification();
  const { t } = useTranslation();
  const {
    onBeginPayment,
    onSuccessfulPayment,
    onFailedPayment,
    infoTransaction,
    setChecked,
    checked,
  } = props;
  const {
    firstName,
    firstSurname,
    email,
    phone,
    amount,
    description,
    concept,
    taxAmount,
    lang,
  } = infoTransaction;

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      cardNumber: "",
      expirationDate: "",
      cvv: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleAuthAndCapture();
    },
  });

  const handleExpirationDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = e.target.value;
    value = value.replace(/[^0-9/]/g, "");

    const [month, year] = value.split("/");

    if (month.length === 2 && !year && !value.includes("/")) {
      value = month;
    } else if (month.length === 2 && !year && !value.includes("/")) {
      value = parseInt(month) > 12 ? "12/" : month + "/";
    } else if (month.length > 2 && !year) {
      const actualYear = month.slice(2);
      value = `${month.slice(0, 2)}/${actualYear}`;
    }

    if (year && year.length > 2) {
      value = value.slice(0, -1);
    }

    formik.setFieldValue("expirationDate", value);
  };

  const { handleChange, handleSubmit, values, touched, errors } = formik;

  const handleAuthAndCapture = useCallback(async () => {
    
    if (onBeginPayment) {
      onBeginPayment();
    }

    const data: AuthAndCapturePaguelofacil = {
      firstName,
      firstSurname,
      cardNumber: values.cardNumber,
      expirationYear: values.expirationDate.split("/")[1],
      expirationMonth: values.expirationDate.split("/")[0],
      cvv: values.cvv,
      amount,
      description: description || "Pago en linea quenecesitas7",
      taxAmount: taxAmount || 0,
      email,
      phone,
      concept: concept || "Pago en linea quenecesitas7",
      lang: lang || "es",
    };

    if (!loading) {
      try {
        setLoading(true);
        const response: ResponsePaguelofacil | any =
          await authAndCapturePaguelofacil(data);
        if (response.data.headerStatus.code === 200) {
          console.log('response de pago con tarjeta de credito', response.data);
          showSuccess("Pago realizado con exito");
          onSuccessfulPayment(response.data.data);
        } else {
          showError("Error al realizar el pago1");
          onFailedPayment();
          setLoading(false);
        }
      } catch (error) {
        showError("Error al realizar el pago2");
        console.error("Error al realizar el pago222", error);
        onFailedPayment();
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Container>
      <form>
        <Row justifyContent="space-between">
          <Checkbox
            checked={checked}
            label={t('credit_card')}
            onClick={setChecked}
          />
          <img
            style={{ width: "100px", height: "50px", objectFit: "contain" }}
            src={CreditCardBrands}
            loading="lazy" alt="Credit Card Brands" title="Credit Card Brands"
          />
        </Row>
        {checked && (
          <DataCardContainer>
            <Input
              label={t('account_holder_name')}
              type="text"
              name="name"
              onChange={handleChange}
              value={values.name}
              helperText={touched.name && errors.name}
            />
            <Input
              label={t('card_number')}
              type="text"
              name="cardNumber"
              onChange={handleChange}
              value={values.cardNumber}
              helperText={touched.cardNumber && errors.cardNumber}
              RightIcon={imageCardType(getCardType(values.cardNumber)) as any}
            />
            <Row justifyContent="space-between">
              <Input
                label={t('expiration_date')}
                type="text"
                name="expirationDate"
                style={{ width: "calc(50% - 10px)" }}
                onChange={handleExpirationDateChange}
                value={values.expirationDate}
                helperText={touched.expirationDate && errors.expirationDate}
              />
              <Input
                label="CVV"
                type="text"
                name="cvv"
                style={{ width: "calc(50% - 10px)" }}
                onChange={(e) => {
                  if (e.target.value.length <= 4) {
                    handleChange(e);
                  }
                }}
                value={values.cvv}
                helperText={touched.cvv && errors.cvv}
              />
            </Row>
            <ButtonContainer>
              <Button type="submit" onClick={() => handleSubmit()}>
                {t('checkout')}
              </Button>
            </ButtonContainer>
          </DataCardContainer>
        )}
      </form>
      <ModalLoader isLoading={loading} />
    </Container>
  );
};

export default CreditCardAuthCapture;
