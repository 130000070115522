import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  border: 1px solid #69727F;
  padding: 4px 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
`

interface IRow {
    width?: string
    justifyContent?: string
    alignItems?: string
    margin?: string
    padding?: string
    flexDirection?: string
    flexWrap?: string
    flex?: string
    height?: string
    border?: string
    borderRadius?: string
    backgroundColor?: string
    color?: string
    fontSize?: string
}

export const Row = styled.div<IRow>`
    width: ${({ width }) => width || '100%'};
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
    align-items: ${({ alignItems }) => alignItems || 'center'};
    margin: ${({ margin }) => margin || '0'};
    padding: ${({ padding }) => padding || '0'};
    flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
    flex-wrap: ${({ flexWrap }) => flexWrap || 'nowrap'};
    flex: ${({ flex }) => flex || '0 1 auto'};
    height: ${({ height }) => height || 'auto'};
    border: ${({ border }) => border || 'none'};
    border-radius: ${({ borderRadius }) => borderRadius || '0'};
    background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
    color: ${({ color }) => color || '#000'};
    font-size: ${({ fontSize }) => fontSize || '1rem'};
`

export const DataCardContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 0px 16px 0;
    gap: 8px;
`

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 24px;

    .div {
        width: 100%;
    }
`